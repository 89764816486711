import { useRouteLoaderData } from 'react-router'
import { type loader as rootLoader } from '~/root'

export function useUserCoordinates() {
	const data = useRouteLoaderData<typeof rootLoader>('root')
	if (!data || data.userCoordinates === undefined) {
		return undefined
	}
	// value is comma separated coordinates, split and return as object
	const [latitude, longitude] = data.userCoordinates.split(',')
	if (!latitude || !longitude) {
		return undefined
	}
	return {
		latitude: parseFloat(latitude),
		longitude: parseFloat(longitude),
	}
}

export const setUserCoordinates =
	async (): Promise<GeolocationPosition | null> => {
		if (!navigator.geolocation) return null

		const position = await new Promise<GeolocationPosition>(
			(resolve, reject) => {
				if (!navigator.geolocation) return null
				navigator.geolocation.getCurrentPosition(resolve, reject)
			},
		)
		if (!position) return null

		await setCoordinates(position)
		return position
	}

export const checkUserLocationAnonymous = async (): Promise<boolean> => {
	const permissionStatus = await navigator?.permissions?.query({
		name: 'geolocation',
	})
	return permissionStatus?.state === 'granted'
}

const setCoordinates = async (location: GeolocationPosition) => {
	const formData = new FormData()
	formData.append('latitude', location.coords.latitude.toString())
	formData.append('longitude', location.coords.longitude.toString())
	await fetch('/api/location/cookies-location', {
		method: 'POST',
		body: formData,
	})
}

export const removeCoordinates = async () => {
	const formData = new FormData()
	formData.append('removeCookie', 'true')
	formData.append('latitude', '0')
	formData.append('longitude', '0')
	await fetch('/api/location/cookies-location', {
		method: 'POST',
		body: formData,
	})
}
